import { VFC, useMemo } from 'react'
import styled from 'styled-components'

export type Props = {
  readonly hour: number
}

export const DailyGreeting: VFC<Props> = ({ hour }) => {
  const greeting = useMemo(() => {
    if (hour >= 6 && hour <= 11) return 'おはようございます'
    if (hour >= 12 && hour <= 15) return 'こんにちは'
    return 'お疲れさまです'
  }, [hour])

  return <StyledGreeting className="daily-greeting">{greeting}</StyledGreeting>
}

const StyledGreeting = styled.h2`
  width: 100%;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 2;
`
