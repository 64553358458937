import { VFC } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

import { Colors, ColorTypeValues, IconName } from '@/styles'

import { Icon } from '@atoms/Icon'

export type Props = {
  readonly href: string
  /**
   * pass for styled method
   */
  readonly className?: string
  readonly iconName?: IconName
  readonly color?: ColorTypeValues
  readonly bold?: boolean
  readonly blank?: boolean
  readonly children: string
}

export const Link: VFC<Props> = ({ href, className, iconName, color = Colors.textLink, bold, blank, children }) => (
  <StyledLink
    className={classnames(className, 'link')}
    href={href}
    color={color}
    bold={bold}
    target={blank ? '_blank' : undefined}
    rel={'noreferrer noopener'}
  >
    {iconName && (
      <Icon style="outlined" color={color}>
        {iconName}
      </Icon>
    )}
    <span className="text">{children}</span>
  </StyledLink>
)

const StyledLink = styled.a<Pick<Props, 'color' | 'bold'>>`
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
  outline: none;

  & > .icon {
    margin-right: 4px;
  }

  & > .text {
    font-weight: ${({ bold }) => bold && 700};
    color: ${({ color }) => color};
    text-decoration: underline;
  }

  &:hover {
    text-decoration: none;
    filter: brightness(0.9);

    & > .text {
      text-decoration: none;
    }
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    filter: brightness(0.9);
    outline: none;

    & > .text {
      text-decoration: none;
    }
  }

  &:active {
    text-decoration: none;
    filter: brightness(0.8);

    & > .text {
      text-decoration: underline;
    }
  }
`
