import { VFC, ReactNode } from 'react'
import styled from 'styled-components'

export type Props = {
  readonly children: ReactNode
}

export const MultiPanelLayout: VFC<Props> = ({ children }) => (
  <StyledMultiPanelLayout>{children}</StyledMultiPanelLayout>
)

const StyledMultiPanelLayout = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 880px;
  height: 560px;

  & > .panel {
    flex: 1;
    padding: 56px 60px;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      & > .daily-greeting {
        margin-top: auto;
        margin-bottom: 0;
        line-height: 1;
        text-align: center;
      }

      & > .title {
        margin-top: auto;
        margin-bottom: 0;
        line-height: 1;
      }

      & > .link {
        margin-top: auto;
      }
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      & > .title {
        margin-bottom: 40px;
      }
    }
  }
`
